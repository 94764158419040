import * as Sentry from "@sentry/vue";

/**
 * 获取自动上下文信息，包括调用函数的文件名、函数名和行号
 */
function getAutoContext(): string {
  const stack = new Error().stack;
  if (!stack) return "";

  const stackLines = stack.split("\n");
  const callerInfo = stackLines[3] || ""; // 第三行通常是调用的具体位置

  // 提取文件、函数和行号
  const match = callerInfo.match(/at\s+(.*)\s+\((.*):(\d+):\d+\)/);
  if (match) {
    const [, functionName, filePath, lineNumber] = match;
    const fileName = filePath.split("/").pop();
    return `${functionName} in ${fileName}:${lineNumber}`;
  } else {
    return callerInfo.trim();
  }
}

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();
  const { vueApp } = nuxtApp;

  /**
   * 设置用户信息到 Sentry
   * @param {object | null} user - 用户信息对象，若为 null 则清除用户信息
   */
  const setUser = (user) => {
    if (user) {
      Sentry.setUser(user);
      console.info("[Sentry] User context set:", user);
    } else {
      Sentry.setUser(null); // 清除用户信息
      console.info("[Sentry] User context cleared");
    }
  };

  /**
   * 统一的错误日志方法
   * @param {Error | string} error - 错误对象或错误信息
   * @param {string} context - 错误发生的上下文信息
   * @param {boolean} reportToSentry - 是否上报到 Sentry，默认是 true
   */
  const logError = (
    error,
    context = getAutoContext(),
    reportToSentry = true
  ) => {
    console.error(`[LogError in ${context}]:`, error);

    if (
      reportToSentry &&
      (config.public.web === "test" || config.public.web === "prod")
    ) {
      Sentry.captureException(error, {
        tags: { context },
        extra: { errorDetails: error },
      });
    }
  };

  /**
   * 统一的信息日志方法
   * @param {string} message - 信息内容
   * @param {string} context - 信息发生的上下文信息
   * @param {boolean} reportToSentry - 是否上报到 Sentry，默认是 true
   */
  const logInfo = (
    message,
    context = getAutoContext(),
    reportToSentry = true
  ) => {
    console.log(`[LogInfo in ${context}]:`, message);

    if (
      reportToSentry &&
      (config.public.web === "test" || config.public.web === "prod")
    ) {
      Sentry.captureMessage(message, {
        level: "info",
        tags: { context },
      });
    }
  };

  // 仅在测试和生产环境中初始化 Sentry
  if (
    process.client &&
    (config.public.web === "test" || config.public.web === "prod")
  ) {
    const tracePropagationTargets =
      config.public.web === "prod"
        ? [
            "localhost",
            /^https:\/\/(www\.)?bestfaceswap\..*\/?/,
            /^https:\/\/api\.bestfaceswap\..*\/?/,
            /^https:\/\/cdnaivideo\.abcfreemusic\.com\/?/,
          ]
        : [
            "localhost",
            /^https:\/\/test\.bestfaceswap\..*\/?/,
            /^https:\/\/apitest\.bestfaceswap\..*\/?/,
            /^https:\/\/cdnaivideo\.abcfreemusic\.com\/?/,
          ];

    Sentry.init({
      app: [vueApp],
      dsn: config.public.dsn,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
      tracesSampleRate: 1.0,
      tracePropagationTargets,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
  }

  // 动态监听用户信息
  const comm = useCommStore();
  watch(
    () => comm.userInfo,
    (newUserInfo) => {
      if (newUserInfo) {
        // 设置用户上下文到 Sentry
        setUser({
          id: newUserInfo.uid,
          email: newUserInfo.email,
          username: newUserInfo.nickname,
        });
      } else {
        // 清除用户上下文
        setUser(null);
      }
    },
    { immediate: true }
  );

  // 挂载全局属性
  vueApp.config.globalProperties.$logError = logError;
  vueApp.config.globalProperties.$logInfo = logInfo;

  return {
    provide: {
      logError,
      logInfo,
    },
  };
});
