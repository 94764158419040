<template>
    <v-bottom-sheet v-model="isVisible" width="100%">
        <slot name="content"></slot>
    </v-bottom-sheet>

    <!-- 按钮来触发底部弹窗 -->
    <!-- <v-btn color="primary" @click="showSheet = true">Show Tips</v-btn> -->
</template>

<script lang="ts" setup>
import { ref } from 'vue';

const props = defineProps({
    modelValue: {
        type: Boolean,
        default: false
    },
});

const isVisible = ref(props.modelValue);

const emit = defineEmits(['update:modelValue']);

watchEffect(() => {
    isVisible.value = props.modelValue;
});

watch(isVisible, (newValue) => {
    emit('update:modelValue', newValue);
});

function onClose() {
    isVisible.value = false;  // 关闭对话框
}
</script>

<style scoped>
.quality-tips {
    text-align: center;
}

.center-text {
    text-align: center;
    color: #fff;
}

.quality-row {
    margin-bottom: 20px;
}
</style>