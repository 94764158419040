<template>
    <section class="relative mx-auto">
        <!-- 背景层 -->
        <div id="box" class="z-20 absolute inset-0 flex items-center justify-center w-full h-full overflow-hidden">
        </div>

        <!-- 渐变层 -->
        <div class="z-30 absolute inset-0 bg-gradient-to-t from-neutral-950 via-transparent to-transparent"></div>

        <!-- 内容层 -->
        <slot></slot>
    </section>
</template>
<script setup lang="ts">
import { BlurGradientBg } from '@/assets/js/BlurGradientBg.module.js';

const props = defineProps({
    height: {
        type: String,
        default: '100vh'
    }
});

const utils = useUtils()
const colorbgCanvas = ref<HTMLElement | null>(null)

onMounted(() => {
    try {
        console.log('color bg');
        let colorbg = new BlurGradientBg({
            dom: "box",
            colors: ["#0a0a0a", "#6b1b00", "#0a0a0a", "#0a0a0a"],
            loop: true
        })
    } catch (error) {
        console.log('load color bg error', error)
    }
});
</script>