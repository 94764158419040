export default defineAppConfig({
  apiBaseAI: import.meta.env.VITE_PUBLIC_API_BASE_AI,
  apiBaseNet: import.meta.env.VITE_PUBLIC_API_BASE_NET,
  videoExt: [
    { ext: 'mp4', mime: 'video/mp4' },
    { ext: 'mov', mime: 'video/quicktime' },
    // { ext: 'mkv', mime: 'video/x-matroska' },
    { ext: 'mpeg', mime: 'video/mpeg' },
    { ext: 'webm', mime: 'video/webm' },
  ],
  imageExt: [
    { ext: 'jpg', mime: 'image/jpeg' },
    { ext: 'jpeg', mime: 'image/jpeg' },
    { ext: 'png', mime: 'image/png' },
    { ext: 'gif', mime: 'image/gif' },
  ],
  // 视频最大时长
  maxVideoDuration: import.meta.env.VITE_WEB === 'prod' ? 15 * 60 * 1000 * 1000 : 0.1 * 60 * 1000 * 1000,
  // 视频裁剪最大时长
  maxTrimVideoDuration: import.meta.env.VITE_WEB === 'prod' ? 15 * 60 * 1000 * 1000 : 0.1 * 60 * 1000 * 1000,
  // 图片尺寸限制
  IMAGE_CONSTRAINTS: {
    MIN_DIMENSION: 160, // 最小尺寸
    MAX_DIMENSION: 4000, // 最大尺寸
    MIN_RATIO: 9 / 21, // 最小比例
    MAX_RATIO: 21 / 9 // 最大比例
  },
  // GIF图支持最大秒数
  MAX_GIF_DURATION: 15 * 1000,
  IMGTOOL_URL: import.meta.env.VITE_WEB === 'dev' ? "http://localhost:3000/bfs" : (import.meta.env.VITE_WEB !== 'prod' ? 'http://test.imgtool.pro/bfs' : 'https://imgtool.pro/bfs')
});
