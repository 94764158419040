<script lang="ts" setup>
import ThemeWrapper from '@/components/ThemeWrapper.vue';
import GlobalLoading from '@/components/GlobalLoading.vue';
import GlobalDownloadApp from '@/components/GlobalDownloadApp.vue';
import api from '~/api';
const utils = useUtils();
const snackbarStore = useSnackbarStore();
const loading = ref(0);
const interval = ref(0) as any;
const bufferValue = ref(0);
const authStore = useAuthStore();
const gtag = useGtag();

function startBuffer() {
  clearInterval(interval.value);

  interval.value = setInterval(() => {
    loading.value += Math.random() * (15 - 5) + 5;
    bufferValue.value += Math.random() * (15 - 5) + 6;
  }, 100);
}

declare global {
  interface Window {
    google: any;
  }
}

// function handleCredentialResponse(response) {
//   console.log('Encoded JWT ID token: ' + JSON.stringify(response));
//   authStore.login(response.credential); // 将用户登录状态更新
// }

async function handleCredentialResponse(response) {
  const idToken = response.credential;
  const payload = parseJwt(idToken);

  try {
    const utmStore = useUtmStore();
    const resp = await api.oneTapLogin({ idtoken: response.credential, utmAll: new URLSearchParams(utmStore.utmParams).toString() });
    if (resp) {
      utils.loginByToken(resp.token);
    } else {
      throw new Error("");

    }
  } catch (error) {
    console.log('login failed', error);
  }
}

function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join('')
  );
  return JSON.parse(jsonPayload);
}

onMounted(() => {
  // Preloader and Progress bar setup
  startBuffer();
  setTimeout(() => {
    loading.value = 100;
    clearInterval(interval.value);
  }, 100);

  // 延迟加载
  setTimeout(() => {
    gtag.initialize();
  }, 3000);


  // 动态加载 Google Identity Services 脚本
  const script = document.createElement('script');
  script.src = 'https://accounts.google.com/gsi/client';
  script.async = true;
  script.defer = true;
  script.onload = () => {
    // Google One Tap 登录初始化
    if (!authStore.isLogin) {
      try {
        const config = useRuntimeConfig();
        window.google.accounts.id.initialize({
          client_id: config.public.googleClientId,
          callback: handleCredentialResponse,
        });
        window.google.accounts.id.prompt(); // 显示 One Tap 登录提示
        console.log('Google One Tap prompt displayed.');
      } catch (error) {
        console.error('Error initializing Google One Tap:', error);
      }
    }
  };
  script.onerror = (error) => {
    console.error('Error loading Google Identity Services script:', error);
  };
  document.head.appendChild(script);
});

</script>
<template>
  <div>
    <div id="preloader" class="flex justify-center items-center w-screen h-screen" v-if="loading < 100" style=" position:
      fixed; z-index: 10000; background: #181A20; ">
      <div class="loader"></div>
    </div>
    <theme-wrapper theme="cyber">
      <v-progress-linear v-model="loading" :active="loading < 100" :buffer-value="bufferValue" absolute color="primary"
        class="top-loading" />
      <v-app>
        <v-main id="main-wrap" class="page-enter-active bg-neutral-950">
          <slot />
        </v-main>
      </v-app>
      <v-snackbar v-model="snackbarStore.visible" :color="snackbarStore.color" timeout="3000" top>
        {{ snackbarStore.message }}
      </v-snackbar>
    </theme-wrapper>
    <GlobalLoading></GlobalLoading>
    <GlobalDownloadApp />
  </div>
</template>

<style lang="scss">
.top-loading {
  z-index: 9999;
}

.loader {
  border: 8px solid black;
  border-top: 8px solid #F06906;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s ease-in-out infinite;
}

@media (max-width: 768px) {
  .loader {
    border: 6px solid transparent !important;
    border-top: 6px solid #F06906 !important;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s ease-in-out infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
