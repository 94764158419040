<template>
    <header id="main-header" class="fixed top-0 z-50 w-full bg-transparent transition-colors duration-300">
        <nav class="flex justify-between items-center p-4 mx-auto max-w-7xl lg:px-8 backdrop-blur-lg" aria-label="Global">
            <div class="flex lg:flex-1">
                <NuxtLink to="/" class="flex items-center p-1.5 -m-1.5">
                    <span class="sr-only">BestFaceSwap</span>
                    <img class="w-auto h-8" src="/idx/bestfaceswap-logo.svg" alt="">
                    <div class="hidden ml-4 text-2xl font-semibold text-white lg:block">BestFaceSwap</div>
                </NuxtLink>
            </div>
            <div class="flex lg:hidden">
                <NuxtLink to="/" class="ml-4 text-2xl font-semibold text-white">BestFaceSwap</NuxtLink>
            </div>
            <div class="flex lg:hidden">
                <button id="mobile-menu-button" type="button" class="inline-flex z-50 justify-center items-center p-2.5 -m-2.5 rounded-md text-neutral-500">
                    <span class="sr-only">Open main menu</span>
                    <img v-if="auth.isLogin" class="w-[30px] h-[30px] rounded-full" :src="comm.userInfo?.avatar || '/images/nobody.svg'" alt="">
                    <svg v-else class="w-6 h-6" fill="#ffffff" viewBox="0 0 24 24" stroke-width="2" stroke="#ffffff" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                    </svg>
                </button>
            </div>
            <div class="hidden lg:flex lg:flex-1 lg:justify-end lg:items-center lg:gap-x-8">
                <NuxtLink to="#" @click.prevent="onClick" class="font-semibold leading-6 duration-300 cursor-pointer text-md text-white/80 hover:text-white">
                    Faceswap
                </NuxtLink>
                <NuxtLink to="/pricing" class="font-semibold leading-6 duration-300 text-md text-white/80 hover:text-white">Pricing
                </NuxtLink>

                <AppDownloadButton v-if="!auth.isLogin" />

                <!-- My History -->
                <template v-if="auth.isLogin">
                    <a href="#" @click.prevent="toggleRotation" class="flex items-center font-semibold leading-6 duration-300 text-md text-white/80 hover:text-white group">
                        My History
                        <svg xmlns="http://www.w3.org/2000/svg" class="ml-1 w-4 h-4 transition-transform duration-500 transform" :class="{ 'rotate-180': isRotated }" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                        <v-menu v-model="menuVisible" activator="parent" open-on-click>
                            <AccountPop></AccountPop>
                        </v-menu>
                    </a>
                </template>

                <AppDownloadButton v-if="auth.isLogin" />
                <!-- Login status -->
                <template v-if="!auth.isLogin">
                    <NuxtLink to="/login/google" class="inline-flex gap-2 justify-center items-center px-6 py-3 text-sm font-semibold text-white bg-opacity-90 rounded-full duration-300 group focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-neutral-800 hover:text-white hover:bg-neutral-600 active:bg-neutral-500">
                        <span>Log in</span>
                    </NuxtLink>

                    <NuxtLink to="/signup" class="inline-flex gap-2 justify-center items-center px-6 py-3 text-sm font-semibold bg-orange-600 bg-opacity-90 rounded-full duration-300 group focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 text-neutral-950 hover:text-neutral-950 hover:bg-orange-700 active:bg-orange-600">
                        <span>Sign Up</span>
                    </NuxtLink>
                </template>
                <template v-else>
                    <!-- avatar头像 -->
                    <v-menu open-on-hover>
                        <template v-slot:activator="{ props }">
                            <img @click="router.push(localePath('/my/account'))" @dragstart="event => event.preventDefault()" v-bind="props" :src="comm.userInfo?.avatar || '/images/nobody.svg'" class="object-cover w-10 h-10 rounded-full cursor-pointer" alt="" data-not-lazy>
                        </template>
                        <AccountInfo></AccountInfo>
                    </v-menu>
                </template>

            </div>
        </nav>
        <v-lazy>
            <Banner></Banner>
        </v-lazy>
        <!-- Mobile menu, show/hide based on menu open state. -->
        <div id="mobile-menu" class="hidden lg:hidden z-60" role="dialog" aria-modal="true">
            <div class="fixed inset-0 z-50"></div>
            <div class="overflow-y-auto fixed inset-y-0 right-0 z-50 px-6 py-6 w-full bg-opacity-80 backdrop-blur-lg bg-neutral-950 sm:max-w-sm sm:ring-1 sm:ring-white/10">
                <div class="flex justify-between items-center">
                    <NuxtLink to="/" class="p-1.5 -m-1.5">
                        <span class="sr-only">BestFaceSwap</span>
                        <img class="w-auto h-8" src="/idx/bestfaceswap-logo.svg" alt="">
                    </NuxtLink>
                    <button id="close-menu-button" type="button" class="p-2.5 -m-2.5 text-white rounded-md">
                        <span class="sr-only">Close menu</span>
                        <svg class="w-6 h-6" fill="#ffffff" viewBox="0 0 24 24" stroke-width="2" stroke="#ffffff" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                <div class="flow-root mt-6">
                    <div class="-my-6 divide-y divide-gray-500/10">
                        <div class="py-6 space-y-2" @click="toggleMenu">
                            <template v-if="auth.isLogin">
                                <NuxtLink to="/face-swap" class="block px-3 py-3 -mx-3 text-base font-semibold leading-7 text-center rounded-full duration-300 text-white/80 hover:text-white hover:bg-neutral-800">
                                    Faceswap</NuxtLink>
                                <NuxtLink to="/pricing" class="block px-3 py-3 -mx-3 text-base font-semibold leading-7 text-center rounded-full duration-300 text-white/80 hover:text-white hover:bg-neutral-800">
                                    Pricing</NuxtLink>
                                <NuxtLink to="/my/uploads" class="block px-3 py-3 -mx-3 text-base font-semibold leading-7 text-center rounded-full duration-300 text-white/80 hover:text-white hover:bg-neutral-800">
                                    My Uploads</NuxtLink>
                                <NuxtLink to="/my/creations" class="block px-3 py-3 -mx-3 text-base font-semibold leading-7 text-center rounded-full duration-300 text-white/80 hover:text-white hover:bg-neutral-800">
                                    My Creations</NuxtLink>
                                <NuxtLink to="/my/account" class="block px-3 py-3 -mx-3 text-base font-semibold leading-7 text-center rounded-full duration-300 text-white/80 hover:text-white hover:bg-neutral-800">
                                    Account</NuxtLink>
                            </template>
                            <template v-else>
                                <NuxtLink to="/face-swap-video" class="block px-3 py-3 -mx-3 text-base font-semibold leading-7 text-center rounded-full duration-300 text-white/80 hover:text-white hover:bg-neutral-800">
                                    Video Faceswap
                                </NuxtLink>
                                <NuxtLink to="/face-swap-photo" class="block px-3 py-3 -mx-3 text-base font-semibold leading-7 text-center rounded-full duration-300 text-white/80 hover:text-white hover:bg-neutral-800">
                                    Photo Faceswap
                                </NuxtLink>
                                <NuxtLink to="/pricing" class="block px-3 py-3 -mx-3 text-base font-semibold leading-7 text-center rounded-full duration-300 text-white/80 hover:text-white hover:bg-neutral-800">
                                    Pricing</NuxtLink>
                            </template>
                        </div>
                        <div class="py-6 text-center">
                            <template v-if="auth.isLogin">
                                <div @click="onLogout" class="inline-flex gap-2 justify-center items-center py-4 pr-6 pl-6 text-sm font-semibold text-white bg-opacity-90 rounded-full duration-300 group focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-neutral-800 hover:text-white hover:bg-neutral-700 active:bg-neutral-600 active:text-gray-100 focus-visible:outline-gray-600">
                                    <span>Log Out</span>
                                </div>
                            </template>
                            <template v-else>
                                <div class="flex flex-col gap-y-6">
                                    <NuxtLink to="/login/google" class="inline-flex gap-2 justify-center items-center py-4 pr-4 pl-6 text-sm font-semibold text-white bg-opacity-90 rounded-full duration-300 group focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-neutral-800 hover:text-white hover:bg-neutral-700 active:bg-neutral-600 active:text-gray-100 focus-visible:outline-gray-600">
                                        <span>Log in</span>
                                    </NuxtLink>

                                    <NuxtLink to="/signup" class="inline-flex gap-2 justify-center items-center py-4 pr-4 pl-6 text-sm font-semibold bg-orange-600 bg-opacity-90 rounded-full group focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 text-neutral-950">
                                        <span>Sign Up</span>
                                    </NuxtLink>
                                </div>

                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>
<script setup lang="ts">
import AccountPop from './AccountPop.vue';
import AccountInfo from './AccountInfo.vue';
import Banner from './Banner.vue';
import type { MaterialWithStatus } from '~/types';
import GooglePlayButton from './GooglePlayButton.vue';
import AppDownloadButton from './AppDownloadButton.vue';

const auth = useAuthStore();
const comm = useCommStore();
const router = useRouter();
const localePath = useLocalePath();

// 引用 DOM 元素
const menuButton = ref<HTMLElement | null>(null);
const closeButton = ref<HTMLElement | null>(null);
const mobileMenu = ref<HTMLElement | null>(null);
const header = ref<HTMLElement | null>(null);
const menuVisible = ref(false);

// 切换菜单显示
const toggleMenu = () => {
    if (mobileMenu.value) {
        mobileMenu.value.classList.toggle('hidden');
        if (!mobileMenu.value.classList.contains('hidden')) {
            header.value?.classList.remove('backdrop-blur-lg');
        } else if (window.scrollY > 0) {
            header.value?.classList.add('backdrop-blur-lg');
        }
    }
};

// 滚动处理函数
const handleScroll = () => {
    if (window.scrollY > 0) {
        header.value?.classList.add('backdrop-blur-lg');
        mobileMenu.value?.classList.add('hidden');
    } else {
        if (header.value) {
            header.value.style.backgroundColor = 'transparent';
            header.value.classList.remove('backdrop-blur-lg');
        }
    }
};

onMounted(() => {
    menuButton.value = document.getElementById('mobile-menu-button') as HTMLElement;
    closeButton.value = document.getElementById('close-menu-button') as HTMLElement;
    mobileMenu.value = document.getElementById('mobile-menu') as HTMLElement;
    header.value = document.getElementById('main-header') as HTMLElement;


    menuButton.value?.addEventListener('click', toggleMenu);
    closeButton.value?.addEventListener('click', toggleMenu);

    window.addEventListener('scroll', handleScroll);
    handleScroll();
});

const onClick = () => {
    // 清空 matObj
    const matObj = useState<MaterialWithStatus>('matObj');
    matObj.value = null;
    router.push('/face-swap');
};

const onLogout = () => {
    // 执行登出操作
    auth.logout();
};

const isRotated = ref(false);

const toggleRotation = () => {
    isRotated.value = !isRotated.value;
};

watch(menuVisible, (newValue) => {
    if (!newValue) {
        isRotated.value = false;
    }
});

</script>
