<template>
    <section class="py-16 mx-auto max-w-7xl px-6 lg:px-8 relative">
        <div class="relative isolate">
            <div class="mx-auto max-w-7xl">
                <div
                    class="mx-auto flex max-w-2xl flex-col gap-16 bg-white/5 px-6 py-6 ring-1 ring-white/10 sm:rounded-3xl sm:p-8 lg:mx-0 lg:max-w-none lg:flex-row lg:items-center lg:py-20 xl:gap-x-20 xl:px-20 rounded-2xl">
                    <div class="relative w-full aspect-[3/2] rounded-3xl overflow-hidden">
                        <img src="/idx/christmas.jpg" alt="" class="absolute inset-0 w-full h-full object-cover">
                    </div>
                    <div class="w-full flex-auto text-center md:text-left">
                        <h2 class="text-3xl font-bold tracking-tight text-white sm:text-4xl">Faceswap Now</h2>
                        <div class="pt-6 text-white/50">Enjoy <span class="text-white">50% off </span>and Unlock Premium
                            Features Now!</div>
                        <ul role="list"
                            class="mt-10 grid grid-cols-1 gap-x-8 gap-y-3 text-base leading-7 text-white sm:grid-cols-2">
                            <li v-for="feature in features" :key="feature" class="flex gap-x-3">
                                <svg class="h-7 w-5 flex-none" viewBox="0 0 20 20" fill="currentColor"
                                    aria-hidden="true">
                                    <path fill-rule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                                        clip-rule="evenodd" />
                                </svg>
                                {{ feature }}
                            </li>
                        </ul>
                        <div class="mt-12 w-full flex sm:justify-between justify-center">
                            <FaceSwapBtn />
                        </div>
                    </div>
                </div>
            </div>
            <div class="absolute inset-x-0 -top-16 -z-10 flex transform-gpu justify-center overflow-hidden blur-3xl"
                aria-hidden="true">
                <div class="aspect-[1318/752] w-[82.375rem] flex-none bg-gradient-to-r from-[#F06906] to-[#6b1b00] opacity-25"
                    style="clip-path: polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)">
                </div>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
import FaceSwapBtn from './FaceSwapBtn.vue';

const features = [
    "Video Face Swap",
    "Photo Face Swap",
    "Video Multi-Face Swap",
    "Photo Multi-Face Swap"
];
</script>